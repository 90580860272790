@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Outfit";
  src: url("../public/font_static/Outfit-Black.ttf") format("truetype");
  font-weight: 900;
}

/* @import url("./styles/variable.less"); */
:root,
.rs-theme-dark {
  --primary-color: #FFAC06 !important;
  --rs-primary-50: #edf2ff !important;
  --rs-primary-100: #dfe6ff !important;
  --rs-primary-200: #c5d0ff !important;
  --rs-primary-300: #a2b1ff !important;
  --rs-primary-400: #7d86fc !important;
  --rs-primary-500: #FFAC06 !important;
  --rs-primary-600: #4c40eb !important;
  --rs-primary-700: #4033cf !important;
  --rs-primary-800: #352ca7 !important;
  --rs-primary-900: #2f2b84 !important;
  --rs-bg-active: var(--rs-primary-500);
  --rs-btn-primary-bg: var(--rs-primary-500);
}

body {
  margin: 0;
  /* background-color: #151515; */
  background-color: #151515;
  color: #E7E9EA;
  font-family: "Outfit", sans-serif;
  /* src: url("../public/font_static/Outfit-Thin.ttf") format("truetype"); */
  /* font-weight: 350; */
  /* max-height: 100vh !important;
  overflow: hidden !important;
  overflow-x: hidden !important; */
}

body,
/* img {
  cursor: default !important;
} */
html {
  height: 100%;
  --wcm-z-index: 99999;
}

#root {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide scrollbars for all elements */
* {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  width: 0px;
  /* Safari and Chrome */
  background: transparent;
  /* Safari and Chrome */
}

.skeleton_loading {
  background-color: #E7E9EA;
}

/* Global styles for MUI components */
.MuiTypography-root {
  font-family: "Outfit", Outfit-Medium !important;
}

.MuiButton-root {
  font-family: "Outfit",Outfit-Medium !important;
}

/* If you want to target all MUI components at once */
.MuiPaper-root,
.MuiDialog-root,
.MuiAlert-root,
.MuiInputBase-root {
  font-family: "Outfit", Outfit-Medium !important;
}

.spark_card_box {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid 151515;
  border-radius: 8px;
  min-width: 400px;
  max-width: 850px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.spark_card_box:hover {
  background-color: 151515;
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.profile_spark_card_box {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid 151515;
  border-radius: 8px;
  width: 49%;
  min-width: 600px;
  padding: 1.25rem;
  margin: 5px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile_spark_card_box:hover {
  background-color: 151515;
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Add styles for inner elements */
.spark_card_header {
  padding-bottom: 1rem;
  border-bottom: 1px solid 151515;
}

.spark_card_index {
  padding: 1rem 0;
  color: rgba(255, 255, 255, 0.8);
}

.spark_card_result {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 0;
}

.spark_card_footer {
  margin-top: 1rem;
}

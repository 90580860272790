.login-container {
    width: 300px;
    margin: auto;
    text-align: center;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
  }
  
  
  button {
    cursor: pointer;
  }
  
  .success-message {
    color: green;
  }
  
  .error-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-message {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .error-message button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .login_box{
    padding-top: 20px;
    height: 500px;
    width:300px;
    margin: 0 auto;
    margin-top: 200px;
    border-radius: 20px;
    padding-inline: 50px;
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.937) 0px 4px 20px;
  }

.rs-modal-content {
    background-color: #333 !important;   /* Set your desired background color */
    color: white; /* Set text color to white for better contrast */
  }
  


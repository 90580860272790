.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #151515;
  color: #E7E9EA;
  border-bottom: 0.5px solid #151515;
  font-size: 4px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 61px;
  z-index: 999;
}

.logo a {
  color: #E7E9EA;
  text-decoration: none;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: rgb(85, 85, 85);
  text-decoration: none;
  font-size: 13px;
}

.nav a.active-link {
  color: #E7E9EA;
  /* Color for active link */
}

/* In your CSS file or a style block */
.hover_effect {
  padding: 3px;
  padding-inline: 10px;
  border: 1px solid gray;
  border-radius: 2px;
  transition: border-color 0.3s ease;
}

.hover_effect_logout {
  padding: 3px;
  padding-inline: 10px;
  cursor: pointer;
}

.hover-effect:hover {
  border-color: #E7E9EA;
}

.hover_logo {
  position: absolute;
  top: 100%;
  left: -40px;
  background-color: #151515;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 100px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  pointer-events: none;
}

.hover_logo_expanded {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.hover_logo_content {
  padding: 10px;
  text-align: center;
  z-index: 9999;
}

.hover_logo_item {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #E7E9EA;
  font-size: 13px;
  display: block;
  text-align: left;
  border-radius: 4px;
}

.hover_logo_item:hover {
  background-color: #333333;
}

.hover_logo_item:not(:last-child) {
  margin-bottom: 5px;
}

@keyframes expandFadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.9); /* Start slightly down and small */
    filter: blur(10px); /* Start with blur */
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1); /* End at normal size and position */
    filter: blur(0); /* End with no blur */
  }
}

/* Default state before animation */
.fade-in {
  opacity: 0; /* Hidden initially */
  transform: translateY(100px) scale(0.9); /* Positioned below with a smaller scale */
  filter: blur(10px); /* Blurred initially */
  animation: expandFadeIn 0.5s ease-out forwards; /* Smooth transition */
  animation-delay: var(0.2, 0s); /* Apply a variable delay */
}

.slide-element {
  opacity: 0;
  transform: scale(0.3);
}

.home_header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 20;
  padding-inline: 5%;
  background-color: rgba(0, 0, 0, 0); /* Start transparent */
  transition: background-color 0.3s ease; /* Smooth transition */
  backdrop-filter: blur(10px); /* Optional: adds a blur effect */
  height: 100px;
  width: 100vw; /* Full viewport width */
}

.home_header.scrolled {
  background-color: rgba(0, 0, 0, 0.313); /* Change to black when scrolled */
}

.messageBox {
  height: 300px;
}

.scroll-effect {
  top: 0;
  z-index: 10;
  transition: transform 0.9s ease-in, opacity 0.8s ease-out; /* Smooth animation */
  opacity: 1; /* Fully visible by default */
  transform: translateY(0); /* Default position */
}

.fade-out {
  transform: translateY(10px); /* Move upwards */
  opacity: 0.1; /* Fully transparent */
  pointer-events: none; /* Prevent interaction */
}
.bannan_1_box {
  position: relative; /* Ensure proper positioning for inner elements */
  width: 100%; /* Default width for larger screens */
  height: auto; /* Default height for larger screens */
}

.bannan_1 {
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(97, 97, 97, 0.3) 0px 0px 20px 5px;
  opacity: 0.95;
  width: 100%; /* Default full width for larger screens */
  height: auto; /* Default height for larger screens */
}
.video_loader {
  height: 579px;
}

/* Specific styling for screens smaller than 600px */
@media (max-width: 600px) {
  .bannan_1_box {
    height: 400px; /* Increase the container height */
    width: 100%; /* Adjust the width as needed */
    overflow: hidden; /* Hide any overflowing content */
  }

  .bannan_1 {
    margin-left: 20px;
    width: auto; /* Allow the image to scale naturally */
    height: 100%; /* Ensure it fills the container height */
    border-radius: 10px 0px 10px;
    object-fit: cover; /* Maintain proportions and cover the container */
    object-position: left; /* Focus on the left part of the image */
  }

  .video_loader {
    height: 200px;
  }
}

.dreamy-text {
  background: linear-gradient(270deg, #7d76fd, #63b2fc);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: dreamy 3s ease-out infinite;
}

@keyframes dreamy {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
